body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    font-weight: 300;

}
#root {
    overflow: hidden;
}
a {
    text-decoration: none;
}
.MuiButton-root {
    box-shadow: none!important;
}

strong {
    color: #004189;
}

/* saira-condensed-100 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 100;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-100.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-100.woff') format('woff');
}
/* saira-condensed-200 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 200;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-200.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-200.woff') format('woff');
}
/* saira-condensed-300 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-300.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-300.woff') format('woff');
}
/* saira-condensed-regular - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-regular.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-regular.woff') format('woff');
}
/* saira-condensed-500 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-500.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-500.woff') format('woff');
}
/* saira-condensed-600 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-600.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-600.woff') format('woff');
}
/* saira-condensed-700 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-700.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-700.woff') format('woff');
}
/* saira-condensed-800 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 800;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-800.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-800.woff') format('woff');
}
/* saira-condensed-900 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Saira Condensed';
    font-style: normal;
    font-weight: 900;
    src: local(''),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-900.woff2') format('woff2'),
    url('./fonts/saira-condensed-v10-latin/saira-condensed-v10-latin-900.woff') format('woff');
}
/* roboto-100 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-100.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-100.woff') format('woff');
}
/* roboto-100italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-100italic.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-100italic.woff') format('woff');
}
/* roboto-300 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-300.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-300.woff') format('woff');
}
/* roboto-300italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-300italic.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-300italic.woff') format('woff');
}
/* roboto-regular - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-regular.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-regular.woff') format('woff');
}
/* roboto-italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-italic.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-italic.woff') format('woff');
}
/* roboto-500 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-500.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-500.woff') format('woff');
}
/* roboto-500italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-500italic.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-500italic.woff') format('woff');
}
/* roboto-700 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-700.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-700.woff') format('woff');
}
/* roboto-700italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-700italic.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-700italic.woff') format('woff');
}
/* roboto-900 - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-900.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-900.woff') format('woff');
}
/* roboto-900italic - latin */
@font-face {
    font-display: fallback;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: local(''),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-900italic.woff2') format('woff2'),
    url('./fonts/roboto-v29-latin/roboto-v29-latin-900italic.woff') format('woff');
}
